.cookielaw {
	position: fixed;
	z-index: 100;
	bottom: 0;
	left: 0;
	width: 100%;
	padding: 30px;
	color: $white;
	background: #4e5b59;
	font-size: 0;
	letter-spacing: 0;
	//font-family: $itcFont;
	display: none;
}

.cookielaw__text {
	
	a {
		text-decoration: underline;
	}
	
	@include font(16px, 22px, $fw: 400, $ls: 0.3px);
	
	@include media-breakpoint-up(lg) {
		padding-right: 30px;
		line-height: toEm(26px, 14px);
		width: 85%;
		display: inline-block;
        vertical-align: middle;
    }
	
}

.cookielaw__button {

	text-align: center;
    margin: 16px 0 0;
    
    .button {
        min-width: 0;
    }

	@include media-breakpoint-up(lg) {
		margin: 0;
		width: 15%;
		display: inline-block;
		vertical-align: middle;		
	}
}