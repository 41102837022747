.main-banner-form-box {
	position: relative;
	z-index: 10;
	background: rgba($white, 0.8);
    @include border-radius(5px);
	overflow: hidden;
	//@include box-shadow(1px 1px 15px rgba(black, 0.19));

	&.show-message {

		.form-message-container {
			@include opacity(1);
			background: rgba($white, 0.65);
			top: 0;
			margin: 0;
			@include transition(margin 0.3s ease-out, opacity 0.3s ease-out, top 0s ease 0);
		}

		.main-banner-form-box-form {
			//@include filter(blur(6px));
			//@include transition(filter 0.5s ease-out);
		}
	}

	.form-message-container {
		position: absolute;
		width: 100%;
		height: 100%;
		top: -10000px;
		z-index: 100;
		@include opacity(0);
		margin: -10px 0 0;
		@include transition(margin 0.2s ease-out, opacity 0.2s ease-out, top 0s ease 0.2s);		

		&.show {
			@include opacity(1);
			top: 0;
			margin: 0;
			@include transition(margin 0.3s ease-out, opacity 0.3s ease-out, top 0s ease 0);
		}
	}
	
	.form-message {
		@include box-shadow(0 0 20px rgba($black, 0.3));
		background: $orange;
		color: $white;
		padding: 50px 20px;
		@include font(16px, 28px);			
		text-align: center;
		@include absolute-center();
		width: calc(100% - 60px);
		left: 30px;
		
		@include border-radius(10px);

		.form-message-close {
			width: 26px;
			height: 26px;
			@include font(20px, 25px);
			text-align: center;
			background: $white;
			@include border-radius(50%);
			position: absolute;
			right: 15px;
			top: 15px;
			cursor: pointer;
			@include opacity(0.85);
		}

		.button {
			margin: 20px auto 0;
			min-width: 0;
			max-width: 200px;
			display: block;
		}
	}

	.main-banner-form-box-header {
		line-height: normal;
		background: $blue;
		color: $white;
        @include font(25px, $fw: 700, $tt: uppercase);
        letter-spacing: toEm(0.75px);
		font-family: $robotoFont;
		position: relative;
    	z-index: 100;

		padding: 22px 0;
		text-align: center;	

		@include media-breakpoint-down(sm) {
			@include font(17px);
			padding: 22px 0;
			letter-spacing: toEm(0.51px);
		}
	}

	.flags {
		margin: 18px 0 0;
	}

	.main-banner-form-box-form {
		padding: 25px 50px 20px;
		@include transition(filter 0.2s ease-out);
		position: relative;

		@include media-breakpoint-down(md) {
			padding: 42px 32px 60px;
			background-color: $white;
		}

		&:after {
			content: "";
			position: absolute;
			background: url("../img/loading.svg") no-repeat center center rgba($white, 0.65);
			background-size: 260px auto;
			width: 100%;
			height: 100%;
			z-index: 100;				
			top: -10000px;
			left: 0;
			@include opacity(0);
			@include transition(opacity 0.2s ease-out, top 0s ease 0.2s);		

		}

		&.loading {
			&:after {
				@include opacity(1);
				top: 0;
				@include transition(opacity 0.3s ease-out, top 0s ease 0);			
			}
		}

		input,
		select {
			margin: 0 0 10px;

			@include media-breakpoint-down(md) {
				border: 1px solid lighten($gray, 25%);
			}
		}	

		.submit {
			min-width: 0;
			
			@include media-breakpoint-down(sm) {
				max-width: 300px;
				margin: 0 auto;

				@include media-breakpoint-down(xs) {
					max-width: 100%;
				}
			}			
		}	
		
		&.blur {
			@include filter(blur(6px));
			@include transition(filter 0.5s ease-out);
		}
	}
}

.toc__required {
	@include font(12px);

	@include media-breakpoint-up(md) {
		margin: 0 0 8px;
	}
}

.main-banner-form-toc {
    @include font(13px, 15px);
    //letter-spacing: toEm(0.14px, 14px);
	color: $gray;
	padding-top: 8px;
	padding-bottom: 30px;

	@include media-breakpoint-down(sm) {
		padding: 24px 0 40px;
		@include font(12px, 15px);
	}

	> div {
		padding: 8px 0;

		a {
			font-weight: 700;
		}

		@include media-breakpoint-down(sm) {
			@include font(12px, 14px);
			padding: 8px 0 16px;
			
		}
	}

	.radiobutton,
	.checkbox {
		font-size: 14px;
		color: $gray;
		margin: 0 15px 0 0;

		@include media-breakpoint-down(sm) {
			@include font(12px);
			vertical-align: middle;
		}
	}
}