*,
*:before,
*:after {
    @include box-sizing(border-box);
    -webkit-tap-highlight-color: transparent;
    outline: none;
	-webkit-appearance: none;
	-webkit-tap-highlight-color: transparent;
}

*:focus {
    outline: none;
}

img {
    max-width: 100%;
    height: auto;
}

main {
	visibility: visible;
}

h1 {
	@include font(45px, $color: $white, $fw: 900, $tt: uppercase);
	letter-spacing: toEm(0.45px, 45px);

	@include media-breakpoint-down(sm) {
		@include font(26px);
	}	
}

h2 {
	@include font(30px, 37px, $color: $blue, $fw: 700);
	letter-spacing: toEm(0.3px, 30px);

	@include media-breakpoint-down(sm) {
		@include font(25px);
	}	
}


b, strong {
    font-weight: 700;
}

a[name] {
	display: inline;
}

a,
span {
    display: inline-block;
    text-decoration: none;
}

p {
    @include font(18px, 30px, 400, $darkgray);
	letter-spacing: toEm(0.18px, 18px);
	
	@include media-breakpoint-down(sm) {
		@include font(16px, 30px);
		letter-spacing: toEm(0.16px, 16px);
	}
}

a {
	color: $orange;
}

em {
	font-style: italic;
}

input[type='text'],
input[type='email'],
input[type='tel'],
select {
	@include border-radius(0);
    @include font(16px, $fw: 400, $color: $darkgray);	
    letter-spacing: toEm(0.16px);
	border: 1px solid transparent;
	width: 100%;
	padding: /*toEm(8px, 34px)*/ 0 15px;
	height: 41px;
	line-height: normal !important;
	font-family: $robotoFont;
	background: $white;
	
	@include input-placeholder {
		color: $gray;
	}

	@include media-breakpoint-down(sm) {
		height: 40px;
		@include font(16px);
		letter-spacing: toEm(0.16px);
	}

	&:focus {
		@include input-placeholder {
			color: $white;
		}
	}
}

.select {
	position: relative;
	background: $white;
	select {
		position: relative;
		z-index: 1;
		background: transparent;
		margin: 0 !important;
	}

	&:after {
		@extend .fonticon;
		@extend .icon-chevron-down:before;
		color: $gray;
		@include transition(0.2s ease-out);
		position: absolute;
		top: toEm(13px, 12px);
		right: toEm(10px, 12px);
		font-size: toEm(12px);

		@include media-breakpoint-down(sm) {
			//top: toEm(12px, 12px);
			right: toEm(10px, 12px);
		}
	}

	@include media-breakpoint-down(sm) {
		&.open {

			&:after {
				@include transform(rotate(180deg));					
			}
		}		
	}
}

.radiobutton,
.checkbox {
	cursor: pointer;
	input[type='radio'],
	input[type='checkbox'] {
		display: none;
	}


	span {
		width: 12px;
		height: 12px;
		border: 1px solid $gray;
		@include border-radius(50%);
		background: $white;

		@include media-breakpoint-down(sm)  {
			width: 13px;
			height: 13px;
			margin: 0 0 toEm(-2px);
		}
	}

	&.error {
		span {
			border-color: red;			
		}
	}

	input[type='radio']:checked + span,
	input[type='checkbox']:checked + span {
		background: $orange;
	}
}

.button {
    $height: 46px;
    min-width: 270px;
    @extend %v_middle-align;
	border: none;
	cursor: pointer;
	background: $orange;
	text-align: center;
    font-family: $robotoFont;
    height: $height;
    @include border-radius($height);
	@include font(18px, $color: $white, $tt: uppercase);
	line-height: 1;
    letter-spacing: toEm(0.54px, 18px);
	padding: 15px 30px;
	margin: 10px 0;

	@include media-breakpoint-down(sm)  {
		//padding: 12px 0 8px;				
		max-width: 300px;
		margin: 0 auto;

		@include media-breakpoint-down(xs) {
			max-width: 100%;
		}
	}

	&.white {
		background: $white;
		color: $orange;
	}
}

@each $colorName, $colorCode in $colorArray {
    .button--#{$colorName} {
		background: $colorCode;
	}
}

.accordion {

	position: relative;
			
	overflow: hidden;

	&:after {
		@extend .fonticon;
		@extend .icon-chevron-down:before;
		@include transition(0.2s ease-out);
		font-size: 14px;
		color: $orange;
		position: absolute;
		top: 5px;
		right: 0;
	}

	&.open {

		&:after {
			@include transform(rotate(180deg));					
		}
	}

	.accordion-title {
		cursor: pointer;
		position: relative;
		z-index: 1;
		padding-right: 30px;
		width: 100%;
	}
	.accordion-content {
		display: none;
	}
}

.form-error {
	display: none;
}

.skew-text {
	@include transform(rotate(-5deg));	
}

.skew-text__line {
	font-style: italic;
	margin: 0 0 toEm(8px,45px) toEm(-10px,45px);
	padding: toEm(9px,45px) toEm(14px,45px) toEm(8px,45px) toEm(60px,45px);
	float: left;
	clear: both;
	white-space: nowrap;
}

.skew-text__line--blue {
	background: $blue;
}

.skew-text__line--red {
	background: $red;
}

.skew-text__line--orange {
	background: $orange;
}

.skew-text__line--green {
	background: $green;
}



.text-left { 
	text-align: left; 
}

.text-right { 
	text-align: right; 
}

.text-center { 
	text-align: center; 
}

.scroll-down {
	$iconSize: 22px;
	position: fixed;
	top: 85%;
	left: 60px;
	z-index: 11;
	@include opacity(0);
	border: toEm(2px, $iconSize) solid $white;
	padding: toEm(16px, $iconSize) toEm(6px, $iconSize);
	height: toEm($iconSize * 2.8, $iconSize);
	@include border-radius(toEm($iconSize, $iconSize));
	@include font($iconSize, $color: $white);
	@include text-shadow(0 0 toEm(3px, $iconSize) rgba($black, 0.15));
	@include box-shadow(0 0 toEm(3px, $iconSize) rgba($black, 0.05));
	@include transition(opacity 0.15s ease);

	&:before {
		content: "Scroll";
		@include absolute-center(all);
		top: toEm(-30px, $iconSize);
		font-size: toEm(10px, $iconSize);
		text-transform: uppercase;
	}
	
	.upsidedown {
		@include transform(rotate(180deg));
	}
	
	.icon {
		animation: scroll-down__animation 1.3s ease-out 1s infinite;
		display: inline-block;
	}

	&.visible {
		@include opacity(1);
	}
}

@keyframes scroll-down__animation {

	0% {
		@include translateY(20%);		
		@include opacity(1);
	}

	60% {
		@include translateY(-20%);
		@include opacity(1);
	}

	100% {
		@include translateY(-20%);
		@include opacity(0);
	}
}

.go-to-top {
	position: fixed;
	z-index: 100;
	bottom: 91px;
	right: 0;
	text-align: center;
	width: 65px;
	height: 65px;
	line-height: 68px;
	visibility: hidden;
	@include border-radius(10px);
	background: $orange;
	color: $white;
	@include translate(100%, 0);
	@include transition(.15s ease-in-out);
	cursor: pointer;
	@include box-shadow(0 3px 30px rgba($black, 0.16));
	

	&.visible {
		visibility: visible;
		@include translate(-128px, 0);
	}

	@include media-breakpoint-down(md) {
		width: 39px;
		height: 39px;
		line-height: 42px;		
		bottom: 63px;
		font-size: 14px;

		&.visible {
			@include translate(-39px, 0);
		}
		
	}
}