@import 
    "compass",
    "compass/reset"
    ,"node_modules/bootstrap-scss/bootstrap-grid"
    ,"node_modules/bootstrap-scss/variables"
    ,"node_modules/bootstrap-scss/mixins/_breakpoints"
    ,"node_modules/slick-slider/slick/slick.scss"
    ,"fonts"
    ,"variables"
    ,"mixin"
    ,"basics"
    ,"fonticon"
    ,"form"
    ,"splash-page"
    ,"thankyou-page"
    ,"cookielaw"
    ;

html,
body {
    overflow-x: hidden;
}

body {
    font-family: $robotoFont;
    @include font(18px, $fw: 400, $color: $gray);
    background: $white;    
}

.logo {
    font-family: $itcFont;
    @include font(62px, $color: $darkgray, $fw: 600);

    @include media-breakpoint-down(sm) {
        @include font(34px);
    }
}

.logo__img {
    width: 43px;
    height: auto;
    //@extend %v_middle-align;
    margin: 0 toEm(-6px, 62px) 0;

    @include media-breakpoint-down(sm) {
        width: 25px;
        margin: 0 0 toEm(-1px, 52px);
    }
}

.header {
    height: 87px;
    line-height: 87px;
    background: $white;

    @include media-breakpoint-down(sm) {
        height: 70px;
        line-height: 70px;
    }
}

.footer {
    background: $darkgray;
    padding: 0 0 22px;
    text-align: center;
    position: relative;
    z-index: 10;

    @include media-breakpoint-down(sm) {
        padding: 0 0 10px;
    }
}

.footer__copy {
    @include font(16px, 30px, $color: white);
    letter-spacing: toEm(0.18px);

    @include media-breakpoint-down(sm) {
        @include font(12px, 33px, $fw: 400);
        letter-spacing: toEm(0.12px);
    }
}

.footer__links {
    margin: 10px 0;

    a {
        @include font(16px, 30px, $color: white);

        &:not(:last-child):after {
            content: "|";
            margin-left: toEm(5px);
        }
    }
}

.footer__logo {

    margin: -63px 0 20px;

    .logo__img {
        width: auto;
    }

    @include media-breakpoint-down(sm) {
        margin: -58px 0 5px;

        .logo__img {
            width: 85px;
        }
    }
}

.herobanner {
	position: relative;
	min-height: 903px;

	padding: 63px 0 84px;
    margin: 0 0 94px;
    
    @include media-breakpoint-down(md) {
        padding: 420px 0 0;
        min-height: 0;
    }

	@include media-breakpoint-down(md) {
        height: auto;        
        min-height: 0;
        margin: 0 0 64px;			
		
		.main-banner-form-box {
			@include box-shadow(0 3px 60px rgba($black, 0.16));
		}
    }

    .scroll-down {
        @include font(30px);
    }
    
    &.herobanner--welfare {
    
        .herobanner__cover {
            @include cover("../img/header_welfare_desktop.jpg", center center);
            //background-color: #4fdbb8;

            &:after {
                content: "";
                @extend %box-fill;
                background: rgba($black, 0.1);
            }
    
            @include media-breakpoint-down(md) {
                height: 505px;
                top: 0;
                left: 0;
                @include transform(none);
                background-position: left center;
                background-size: auto 250%;
            }
            
            @include media-breakpoint-down(sm) {
                height: 495px;            
                //background-image: url("../img/header_welfare_mobile.jpg");
                background-position: 20% center;
                background-size: auto 180%;
            }
        }        
    }

    &.herobanner--cadhoc {
    
        .herobanner__cover {
            @include cover("../img/header_cadhoc_desktop.jpg", center center);
            background-color: transparent;

            &:after {
                content: "";
                @extend %box-fill;
                background: rgba($black, 0.12);
            }

            @include media-breakpoint-down(md) {
                height: 505px;
                top: 0;
                left: 0;
                @include transform(none);
                background-position: 250px top;
            }

            @include media-breakpoint-between(sm, md) {
                background-position: 0 70%;
                background-size: auto 160%;
            }
            
            @include media-breakpoint-down(sm) {
                height: 495px;            
                //background-image: url("../img/header_welfare_mobile.jpg");
                background-position: 10% -20px;
            }
        }

        @include media-breakpoint-between(sm, md) {
            h1 {
                @include font(30px);
            }
        }

        @media (max-width: 1372px) {
            h1 {
                @include font(40px);
            }
        }

        @media (max-width: 1230px) {
            h1 {
                @include font(32px);
            }
        }

        .herobanner__copy {
            @include media-breakpoint-down(sm) {
                left: -29px;

                h1 {
                    @include font(25px);
                }
            }
        }
    }

    &.herobanner--buonipasto {
    
        .herobanner__cover {
            @include cover("../img/header_buonipasto_desktop.jpg", center center);

            @include media-breakpoint-between(md, md) {
                background-position: 0 center;
                background-size: auto 240%;
            }

            @include media-breakpoint-down(md) {
                height: 505px;
                top: 0;
                left: 0;
                @include transform(none);
                //background-position: 250px top;
            }
            
            @include media-breakpoint-down(sm) {
                height: 495px;            
                background-image: url("../img/header_buonipasto_mobile.jpg");
                background-position: left top;
            }
        }

        
        @media (max-width: 1372px) {
            h1 {
                @include font(40px);
            }
        }
        
        @media (max-width: 1230px) {
            h1 {
                @include font(32px);
            }
        }

        @include media-breakpoint-between(sm, md) {
            h1 {
                @include font(32px);
            }
        }

        @include media-breakpoint-down(sm) {
            h1 {
                @include font(24px);
            }
        }
    }
}

.herobanner__copy {
	position: absolute;
	top: 80px;
    left: -10px;
    
    @include media-breakpoint-down(sm) {
        top: 30px
    }
}

.services {
    padding: 97px 0 145px;
    text-align: center;    

    @include media-breakpoint-down(md) {
        padding: 60px 0 54px;
    }
    
}

.services__list {
    padding: 84px 0 0;

    @include media-breakpoint-down(sm) { 
        padding: 40px 0 0;
    }

    @include media-breakpoint-down(xs) { 
        text-align: left;
    }
}

.service__icon {
    @include font(86px, $color: $blue);
    min-height: 95px;

    @include media-breakpoint-down(lg) {
        @include font(72px);
    }

    @include media-breakpoint-down(sm) {
        @include font(54px);
        min-height: 0;
        width: 60px;
        text-align: center;
    }
}

.service--heart {

    .service__icon {

        @include font(79px);
    
        @include media-breakpoint-down(sm) {
            @include font(54px);
        }
    }
}

.service--hat {

    .service__icon {

        @include font(84px);
    
        @include media-breakpoint-down(sm) {
            @include font(66px);
        }
    }
}

.service--plane {

    .service__icon {

        @include font(66px);
    
        @include media-breakpoint-down(sm) {
            @include font(50px);
        }
    }
}

.service--coin {

    .service__icon {

        @include font(76px);
    
        @include media-breakpoint-down(sm) {
            @include font(62px);
        }
    }
}

.service--smile {

    .service__icon {

        @include font(64px);
    
        @include media-breakpoint-down(sm) {
            @include font(50px);
        }
    }
}

.service--gift {

    .service__icon {

        @include font(66px);
    
        @include media-breakpoint-down(sm) {
            @include font(50px);
        }
    }
}

.service {

    p {
        color: $darkgray;
    }

    @include media-breakpoint-down(md) { 
        margin: 0 0 45px;
    }

    @include media-breakpoint-down(sm) { 
        margin: 0 0 12px;

        .service__icon,
        p {
            display: inline-block;
            vertical-align: middle;
        }

        p {
            width: calc(100% - 65px);
            @include font(16px, 25px);
            padding-left: toEm(16px);
            text-align: left;
            vertical-align: top;        

            @include media-breakpoint-down(xs) { 
                vertical-align: middle;        
            }
        }
    }

    @include media-breakpoint-up(xl) {

        p {
            padding: 0 10px;
        }
    }
}

.plus {
    //padding: 0 0 115px;
    text-align: center;   

    h2 {
        padding: 0 0 90px;
    }

    @include media-breakpoint-down(md) {
        padding: 0 0 10px;

        h2 {
            padding: 0 0 36px;
        }
    }

    .plus__title {
        @extend h2;
        color: $darkgray;
        padding: 45px 0 40px;;
        text-align: left;

        @include media-breakpoint-down(sm) {
            padding: 20px 0 40px;
        }
    }
}

.plus__list {
    text-align: left;
    padding: 0 0 30px;
}

.plus__point {
    padding: 0 15px 16px 44px;
    @include font(16px, 30px);
    position: relative;
    letter-spacing: toEm(0.16px);

    @include media-breakpoint-down(sm) {
        line-height: toEm(25px);
    }

    &:before {
        position: absolute;
        left: 0;
        top: toEm(1px);
        content: "";
        @extend %v_middle-align;
        width: 25px;
        height: 25px;
        background: {
            image: url('../img/check.png');
            size: 100% auto;
        }
    }
}


.calculate {
    text-align: center;
    padding: 54px 0 0;
    background: $lightgray;    
}

.calculate__options {
    font-size: 0;
    margin: 0 0 25px;

    @include media-breakpoint-up(lg) {
        display: inline-block;
        margin: 0 0 9px;
        padding: 16px;
        background: $white;
        @include border-radius(172px);
    }

    @include media-breakpoint-down(sm) {
        margin: 0 0 14px;
    }    
}

.calculate__copy {
    @include font(22px, 30px, $ls: 0.22px);    
    padding: 18px 0 52px;

    @include media-breakpoint-down(sm) {
        @include font(18px);
        padding: 18px 0 50px;
    }
}

.option {
    margin: 0 0 11px;
    @include font(16px, $ls: 0.16px);
    text-align: center;
    padding: 20px;
    width: 100%;
    height: 80px;
    @include border-radius(95px);
    @extend %v_middle-align;
    background: rgba($gray, 0.1);
    color: $blue;
    @include transition(0.2s ease-in-out);
    position: relative;
    cursor: pointer;

    @include media-breakpoint-between(md, lg) {
        width: 184px;        
        padding: 12px 20px;
        margin: 0 5px 11px;
    }
    
    @include media-breakpoint-up(lg) {
        /*
        height: 95px;
        width: 224px;        
        padding: 12px 50px;
        */
        height: 140px;
        width: 280px;        
        padding: 35px 50px;
        @include font(18px, 18px, $ls: 0.18px);
        margin: 0 9px;
    }

    &:after {
        @include triangle(bottom, $blue, 14px);
        content: "";
        position: absolute;
        left: 50%;
        top: 100%;
        @include translate(-50%, -50%);
        @include opacity(0);
        @include transition(0.1s ease-in-out);
        display: none;

        @include media-breakpoint-up(md) {
            display: block;
        }
    }
    
    &.chosen,
    &:hover {
        color:  $white;
        background: $blue;  
        @include opacity(0.65);
    }

    &.chosen {
        @include opacity(1);

        &:after {
            @include opacity(1);
            @include translate(-50%, 0);            
            @include transition(0.1s ease-in-out 0.2s);
        }
    }
}

.option__copy {

    @include font(17px, $fw: 700, $ls: 0.17px);
    padding: toEm(4px, 17px) 0 0;

    @include media-breakpoint-up(md) {
        @include font(15px, 22px, $fw: 700);        
    }
}

.cookie-policy {
    padding: 40px 0 120px;

    @include media-breakpoint-up(md) {
        padding: 80px 0 160px;
    }
}

.welfare {

    .option__amount {
        @include font(50px, $fw: 700);
        line-height: 1;        
    }

    .main-banner-form-toc {
        .toc__link,
        .accordion:after {
            color: $blue;
        }
        .checkbox input[type='checkbox']:checked + span,
        .radiobutton input[type='radio']:checked + span {
            background-color: $blue;
        }
    }

    .main-banner-form-box {

        .button.submit {
            @extend .button--blue;
        }
    }

    .go-to-top {
        background: $blue;
    }
    
    @include media-breakpoint-down(md) {
        .option {
            padding: 12px;
        }

        .option__amount {
            @include font(38px);
        }
    }
}

.safe-results {
    //background: $blue;
    padding: 25px 0 65px;
    color: $darkgray;

    @include media-breakpoint-down(md) {
        padding: 0 0 50px;
    } 

    @include media-breakpoint-down(sm) {
        padding: 0 0 30px;
    }
}

.safe-result-amount {
   // float: left;
    //width: 50%;    
    text-align: center;
    @include border-radius(10px);
    @include box-shadow(0 3px 60px rgba($black, 0.16));

    padding: 22px 0 40px;

    @include font(18px, $color: $blue, $ls: 0.18px);

    > span {
        @include font(85px, $color: $darkgray, $fw: 700, $ls: 0.10px);
        padding: toEm(38px, 85px) 0 0;

        @include media-breakpoint-down(lg) {
            @include font(72px, $ls: 0.5px);            
        }

        @include media-breakpoint-down(md) {
            @include font(50px, $ls: 0.5px);
            padding: toEm(12px, 50px) 0 0;
        }
    }

    .currency {
        content: "€";
        @include font(45px, $fw: 700);
    }

    @include media-breakpoint-down(md) {
        padding: 18px 0 26px;
        margin: 0 0 7px;
        @include font(14px, $color: $blue, $ls: 0.14px);
    }
}

small {
    font-size: toEm(8px);

    @include media-breakpoint-down(sm) {
        font-size: toEm(6px);
    }
}

.contactus {
    padding: 110px 0 90px;
    text-align: center;

    h2 {
        padding: 0 0 30px;
    }

    @include media-breakpoint-down(md) {
        padding: 55px 0 52px;
    }
}

.associated {
    padding: 54px 0;
    background: $orange;
    color: $white;
    text-align: center;
    overflow: hidden;

    @include media-breakpoint-up(lg) {
        padding: 54px 0 54px 30px;
    }

    h2 {
        color: $white;
        padding: 0 0 52px;
        font-weight: 500;
    }

    @include media-breakpoint-down(sm) {
        padding: 54px 0 32px;

        h2 {
            padding: 0 0 5px;
        }
    }
}

.associated__block {
    display: inline-block;
    text-align: center;
    padding: 0 45px;    
    @include font(60px, $fw: 700, $ls: 0.3px);

    @include media-breakpoint-down(sm) {
        @include font(50px, $fw: 700, $ls: 0.5px);
        padding: 42px 0;
        display: block;
    }

    & > span {
        @include font(30px, $fw: 400);        
        display: block;
        padding: 8px 0 0;   

        @include media-breakpoint-down(sm) {
            @include font(25px);
        }
    }

    &:not(:nth-of-type(1)) {
        border-left: 1px solid $white;

        @include media-breakpoint-down(sm) {
            border: none;
            border-top: 1px solid $white;
        }
    }
}

.bridge {
    position: relative;

    height: 325px;
    overflow: hidden;

    @include media-breakpoint-down(sm) {
        height: 465px;
    }

    .cover {
        @include cover("../img/welfare_bridge_bg_desktop.jpg", 60% top);

        
        &:after {
            background: rgba($black, 0.25px);
            @extend %box-fill;
            content: "";
            display: none;
        }

        @include media-breakpoint-down(md) {
            //background-position: -170px top;
        }

        @include media-breakpoint-between(md, lg) {
            //background-position: 15% top;
        }
        
        @include media-breakpoint-down(sm) {
            //background-image: url('../img/cadhoc_bridge_bg_mobile.jpg');
            background-position: 25% top;
            
            &:after {
                display: block;
            }
        }        
    }
}

.bridge__content {
    @include absolute-center(all);

    h2 {
        padding: 15px 0 23px;

        @include media-breakpoint-down(sm) {
            padding: 0 0 15px;
        }
    }

    @include media-breakpoint-between(md, md) {
        //left: 75%;
    }
}

.bridge--cadhoc {
    .cover {
        background-image: url("../img/cadhoc_bridge_bg_desktop.jpg");
        background-position: 40% top;

        @include media-breakpoint-down(sm) {
            //background-image: url("../img/welfare_bridge_bg_mobile.jpg");
            background-position: 20% top;
        }
    }
}

.bridge--buonopasto {
    .cover {
        background-image: url("../img/buonipasto_bridge_bg_desktop.jpg");
        background-position: center top;

        @include media-breakpoint-between(lg, lg) {
            background-position: 40% top;
        }

        @include media-breakpoint-down(sm) {
            //background-image: url("../img/buonipasto_bridge_bg_mobile.jpg");
            background-position: 22% top;
        }
    }
}



// Pagina CADHOC

.cadhoc {
    .main-banner-form-box-header {
        background: $red;
    }

    h2 {
        color: $red;
    }

    .main-banner-form-toc {
        .toc__link,
        .accordion:after {
            color: $red;
        }
        .checkbox input[type='checkbox']:checked + span,
        .radiobutton input[type='radio']:checked + span {
            background-color: $red;
        }
    }

    .main-banner-form-box {

        .button.submit {
            @extend .button--red;
        }
    }

    .go-to-top {
        background: $red;
    }
    
    @include media-breakpoint-up(sm) {
       
        .services__list {
            display: table;
            table-layout: fixed;
            padding-top: 64px;
        }

        .service {
            display: table-cell;
            vertical-align: top;
            padding: 0 25px;
        }
    }

    @include media-breakpoint-between(sm, md) {
        .services {
            padding: 50px 0 5px;
        }

        .services__list {
            display: block;       
            font-size: 0;     
        }

        .service {
            display: inline-block;
            width: 50%;
            padding: 0 15px;
            vertical-align: top;

            p {
                display: block;
                width: 100%;
                padding: 0 0 15px;
            }
        }
    }

    @include media-breakpoint-down(sm) {
        .services {
            padding: 60px 0 40px;
        }

        .service {
            margin: 0 0 24px;

            br {
                display: none;
            }
        }
    }

    .service__icon {
        color: $red;
        @include font(70px);

        @include media-breakpoint-down(sm) {
            @include font(52px);
        }
    }

    .service--book,
    .service--mobile-card,
    .service--ok {

        .service__icon {

            @include font(66px);
        
            @include media-breakpoint-down(sm) {
                @include font(50px);
            }
        }
    }

    .plus {
        padding: 0 0 60px;

        h2 {
            padding: 0 0 12px;
        }             
        
        .plus__title {
            color: $darkgray;
            padding: 0 0 40px;
        }

        @include media-breakpoint-down(sm) {

            padding-bottom: 52px;

            h2 {
                padding: 0 0 24px;
            }

            .plus__copy {
                padding: 0 0 35px;
            }
        }
    }   
    
    .plus__copy {
        padding: 0 0 92px;
    }

    .plus__image {
        margin: 90px 0 45px;
    }
    

    .plus__point {
        &:before {
            background-image: url("../img/check--red.png");
        }
    }

    .associated {
        h2 {
            color: $white;
        }
    }

    .bridge {
        h2 {
            color: $white;
        }        
    }
}



.calculate__claim {
   @include font(35px, $ls: 0.35px);
   padding: 20px 0;

   @include media-breakpoint-down(sm) {
        @include font(20px, $ls: 0.2px);
        padding: 0 0 20px;
    }
}

.calculate--cadhoc {
    .option {
        color: $darkgray;

        @include media-breakpoint-down(sm) {
            padding: 16px;
        }

        &:after {
            border-top-color: $red;
        }

        &.chosen,
        &:hover {
            color: $white;
            background: $red;  
            @include opacity(0.65);

            .option__copy {
                color: $white;
            }
        }

        &.chosen {
            @include opacity(1);
            color:  $white;

            .option__copy {
                color: $white;
            }
        }
    }

    .option__copy {
        @include font(50px, 32px, $color: $red);   

        @include media-breakpoint-down(md) {
            @include font(38px, 22px); 
            padding: toEm(8px, 38px) 0 0;
        }          
    }

    .safe-result-amount {
        color: $red;
    }
}

.shop {
    /* min-height: 52px;
    margin: 0 0 20px;
    float: left;
    width: 20%;
    text-align: center;
    padding: 0 35px; */

    @include media-breakpoint-up(lg) {
        flex: 0 0 20%;
        margin: 0 0 30px;
    }

    img {
        display: inline-block;
        max-width: 160px;
    }    

    @include media-breakpoint-down(md) {
        padding: 0 30px;
        margin: 0;
    }


    @include media-breakpoint-down(sm) {
        min-height: 0;
        margin: 0;
        float: none;
        position: relative;
        height: 100px;

        img {
            max-height: 100%;
            max-width: 200px;
            //@include absolute-center(all);
        }
    }
}

.shops__list {
    overflow: hidden;
    margin: 0 -35px;

    @include media-breakpoint-up(lg) {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    //@include media-breakpoint-up(lg) {
    .slick-slide > div {
        display: table;
        width: 100%;

        .shop {
            display: table-cell !important;
            height: 120px;
            vertical-align: middle;
            text-align: center;
        }
    }

    .slick-dots {

        margin: 0;

        @include media-breakpoint-down(sm) {
            margin: 35px 0 0;
        }

        li {
            display: inline-block;
            $size: 8px;
            font-size: 0;
            background: $gray;
            border: none;
            @include border-radius($size);
            line-height: 1;
            width: $size;
            height: $size;
            margin: 0 5px;

            &.slick-active {
                background: $darkgray;
            }
            
            button {
                font-size: 0;
                border: none;
                background: transparent;                
            }
        }
    }
}

.buonipasto {
    .main-banner-form-box-header {
        background: $green;

        @include media-breakpoint-up(lg) {
            @include font(17px, 23px);            
        }

        @include media-breakpoint-down(xs) {
            //@include font(14px);   
            padding: 12px 0;         
        }
    }

    h2 {
        color: $green;
    }

    .main-banner-form-toc {
        .toc__link,
        .accordion:after {
            color: $green;
        }
        .checkbox input[type='checkbox']:checked + span,
        .radiobutton input[type='radio']:checked + span {
            background-color: $green;
        }
    }

    .main-banner-form-box {

        .button.submit {
            @extend .button--green;
        }
    }

    .go-to-top {
        background: $green;
    }

    .services {

        @include media-breakpoint-down(sm) {
            padding: 36px 0 54px;
        }
    }

    .service {
        @include media-breakpoint-up(lg) {
            p {
                padding: 0;
            }
        }

        .service__icon {
            color: $green;
        }
    }

    .plus {
        padding: 0 0 115px;

        h2 {
            padding: 0 0 42px;

            @include media-breakpoint-down(sm) {
                padding: 0 0 25px;
            }
        }
    }

    .plus__copy {
        padding: 0 0 68px;

        @include media-breakpoint-down(sm) {
            padding: 0 0 58px;
        }
    }

     .plus__image {
         margin: 0 0 28px;

         @include media-breakpoint-down(sm) {
             margin: 0 0 14px;
         }
     }

     .plus__image-caption {
         @include media-breakpoint-down(sm) {
             padding: 0 15px 42px;
         }
     }

     .calculate {

        @include media-breakpoint-down(sm) {
            padding-top: 38px;
        }

        .option {     
            
            &:after {
                border-top-color: $green;
            }
         
            &.chosen,
            &:hover {
                color: $white;
                background: $green;  
                @include opacity(0.65);

                .option__copy {
                    color: $white;
                }
            }

            &.chosen {
                @include opacity(1);
                color:  $white;

                .option__copy {
                    color: $white;
                }
            }
        }

        .option__copy {
            color :$green;
        }

        .safe-result-amount {
            color: $green;
            padding: {
                left: 15px;
                right: 15px;
            }
        }
    }

    .associated {

        h2 {
            color: $white;
        }
    }

    .service {
        @include media-breakpoint-between(sm, md) {
            margin: 0 0 42px;
        }
    }

    .service__icon {
        color: $red;
        @include font(64px);

        @include media-breakpoint-down(sm) {
            @include font(46px);
        }
    }

    .service--italia {
        img {
            height: 80px;

            @include media-breakpoint-down(sm) {
                height: 60px;
            }
        }

    }

    .service--documents {

        .service__icon {

            @include font(70px);
        
            @include media-breakpoint-down(sm) {
                @include font(50px);
            }
        }
    }

    .bridge {
        h2 {
            color: $white;
        }        
    }
}