@charset "UTF-8";

@font-face {
  font-family: "upday";
  src:url("../fonts/fonticon/upday.eot");
  src:url("../fonts/fonticon/upday.eot?#iefix") format("embedded-opentype"),
    url("../fonts/fonticon/upday.woff") format("woff"),
    url("../fonts/fonticon/upday.ttf") format("truetype"),
    url("../fonts/fonticon/upday.svg#upday") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "upday" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.fonticon,
[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "upday" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-dots-three-horizontal:before {
  content: "\e900";
}
.icon-economici:before {
  content: "\e901";
}
.icon-ordinabili:before {
  content: "\e902";
}
.icon-negozi:before {
  content: "\e903";
}
.icon-arrowup:before {
  content: "\61";
}
.icon-target:before {
  content: "\62";
}
.icon-book:before {
  content: "\63";
}
.icon-coin:before {
  content: "\65";
}
.icon-documents:before {
  content: "\66";
}
.icon-gift:before {
  content: "\67";
}
.icon-hat:before {
  content: "\68";
}
.icon-mail:before {
  content: "\6a";
}
.icon-mobile-card:before {
  content: "\6b";
}
.icon-money:before {
  content: "\6c";
}
.icon-ok:before {
  content: "\6d";
}
.icon-plane:before {
  content: "\6e";
}
.icon-smile:before {
  content: "\6f";
}
.icon-chevron-down:before {
  content: "\70";
}
.icon-check:before {
  content: "\64";
}
.icon-cumulo:before {
  content: "\72";
}
.icon-italia:before {
  content: "\71";
}
.icon-hearth:before {
  content: "\69";
}
