@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&display=swap');

$itcFont: "ITC Kabel Std";
$robotoFont: 'Roboto', sans-serif;
$fontIcon: 'upday';

$orange: #F59100;
$darkgray: #4F5D5B;
$gray: #76838A;
$lightgray: #F9FAFA;
$blue: #00b3e6;
$red: #e30613;
$green: #64b245;
$white: #ffffff;
$black: #000000;

$colorArray: (
    "orange": $orange,
    "darkgray": $darkgray,
    "gray": $gray,
    "lightgray": $lightgray,
    "blue": $blue,
    "red": $red,
    "white": $white,
    "black": $black,
    "green" : $green
);

@each $colorName, $colorCode in $colorArray {
    .#{$colorName} {
		color: $colorCode;
	}
}