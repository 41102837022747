
.herobanner {

    &.herobanner--splash {
        height: 412px;
        text-align: center;
        padding-top: 82px;
        margin: 0 0 -132px;   
        min-height: 0; 
    
        .herobanner__cover {
            height: 412px;
            @include cover("../img/header_splash_desktop.jpg");
            background-color: $lightgray;
    
            @include media-breakpoint-down(sm) {
                height: 400px;
                background-image: url("../img/header_splash_mobile.jpg");
            }
        }
    
        @include media-breakpoint-down(sm) {
            height: 400px;
            padding-top: 22px;
        }
    
        h1 {
            @include font(35px, 42px, $fw: 700, $color: $orange, $tt: none, $ls: 0.35px);
            padding: 0 25px;
            
            @include media-breakpoint-down(sm) {
                @include font(25px, 30px, $ls: 0.30px);
            }
        }
    
        p {        
            padding: 10px 0 0;  
            
            @include media-breakpoint-down(sm) {
                padding: 18px 25px 0;
                line-height: toEm(25px);
            }
        }
    }
}

.services-type {
    @include media-breakpoint-down(sm) {
        padding: 0 0 54px;
    }
}

.service-type {
    overflow: hidden;
    padding: 0 0 107px;
    position: relative;

    @include media-breakpoint-down(sm) {
        padding: 0 0 60px;
    }

    .skew-text {
        position: absolute;
        top: 30px;
        left: -38px;
        @include font(40px, $color: $white, $ls: 0.40px, $fw: 700, $tt: uppercase);

        @include media-breakpoint-down(sm) {
            @include font(23px);
            left: -20px;
        }
    }
}

.service-type__image {
    position: relative;
    height: 445px;

    @include media-breakpoint-down(sm) {
        height: 282px;
    }
}

.service-type--welfare {
    .cover {
        @include cover("../img/header_welfare_desktop.jpg");
        background-position: left top;
    }
}

.service-type--cadhoc {
    .cover {
        @include cover("../img/header_cadhoc_desktop.jpg");
        background-position: -76px top;

        @include media-breakpoint-down(md) {
            background-size: auto 140%;
        }

        @include media-breakpoint-down(sm) {
            background-position: -56px top;
        }
    }
}

.service-type__copy {
    padding: 30px 0 20px;
    color: $darkgray;

    @include media-breakpoint-down(sm) {
        padding: 20px 0 10px;
    }

    @include media-breakpoint-between(md, lg) {
        min-height: 140px;
    }
}