
.herobanner {

    &.herobanner--thankyou {
        margin: 0 0 48px;   
        padding: 0; 

        .container {
            position: relative;
            height: 100%;
        }

        @include media-breakpoint-down(lg) {
            margin: 0 0 60px;   
        }

        @include media-breakpoint-down(sm) {
            margin: 0 0 46px;   
        }
    
        .herobanner__cover {
            @include cover("../img/header_thankyou_desktop.jpg");
            
            &:after {
                @extend %box-fill;
                background: rgba($black, 0.2);
                content: "";
            }
            
            @include media-breakpoint-down(sm) {
                background-image: url("../img/header_thankyou_mobile.jpg");
            }
        }

        .herobanner__copy {
            @include absolute-center(all);
            width: 100%;

            @include media-breakpoint-down(sm) {
                width: percentage(11/12);                
            }
        }
    
        @include media-breakpoint-down(sm) {
            height: 400px;
            padding-top: 22px;
        }
    
        h1 {
            color: $white;            
        }
    
        p {        
            color: $white;
        }
    }
}

.thankyou {

    h2 {
        padding: 0 0 6px;

        @include media-breakpoint-up(lg) {
            @include font(35px);            
        }
    }

    .services-type {
        padding: 80px 0 127px;
        
        @include media-breakpoint-down(lg) {
            padding: 52px 0 67px;
        }

        @include media-breakpoint-between(lg, xl) {
            padding: 32px 0 127px;
        }

        @include media-breakpoint-down(sm) {
            padding: 32px 0 90px;
        }
    }

    .service-type {
        //height: 350px;
        padding: 0;

        @include media-breakpoint-down(md) {
            padding: 0 0 55px;
        }

        .skew-text {
            @include font(35px, $ls: 0.10px);

            @include media-breakpoint-down(xs) {
                height: 350px;
                @include font(27px, $ls: 0.27px);
                left: -12px;
            }
        }

        .service-type__image {
            @include media-breakpoint-up(lg) {
                height: 350px;
            }

            margin: 0 0 30px;
        }

        @include media-breakpoint-up(md) {

            &.service-type--welfare {
                .cover {
                    background-color: #4fdbb8;
                    background-position: 76px 0;
                }
            }

            &.service-type--cadhoc {
                .cover {
                    background-position: 0 0;
                }
            }            
        }

        @include media-breakpoint-up(lg) {

            &.service-type--welfare {
                .cover {
                    background-color: #4fdbb8;
                    background-position: -26px 0;
                }
            }

            &.service-type--cadhoc {
                .cover {
                    background-position: -50px 0;
                }
            }            
        }
    }

    .service-type--buonipasto {
        .cover {
            @include cover("../img/header_buonipasto_desktop.jpg");
            background-position: left top;

            @include media-breakpoint-between(sm, md) {
                background-size: auto 130%;
                background-position: left center;
            }    
        }
    }
}
