// centramento in absolute
@mixin absolute-center($direction: vertical) {
	position: absolute;
	top: 50%;
	@include translate(0, -50%);

	@if($direction==all) {
		left: 50%;
		@include translate(-50%, -50%);
	}
}

// centratura verticale
%v_middle-align {
	display: inline-block;
	vertical-align: middle;
}

%box-fill {
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
}

@mixin cover($imageUrl, $position: center top) {
	@extend %box-fill;
	@include absolute-center(all);

	background: {
		image: url($imageUrl);
		position: $position;
		repeat: no-repeat;
		size: cover;
		color: transparent;
	}
}

@mixin roundIcon($size: 23px, $color: $white, $backgroundcolor: $yellow, $content: "\64") {
	@extend .fonticon;
	content: $content;
	color: $color;
	font-size: 8px;
	@extend %v_middle-align;
	width: $size;
	height: $size;
	background: $backgroundcolor;
	line-height: $size;
	text-align: center;
	@include border-radius($size);
	margin: toEm(-4px) 0 0 toEm(15px);
}

@mixin triangle($direction, $color, $width: 15px) {
    border: $width solid transparent;

    @if($direction=="top") {
        border-bottom-color: $color;
    }

    @if($direction=="bottom") {
        border-top-color: $color;
    }

    @if($direction=="left") {
        border-right-color: $color;
    }

    @if($direction=="right") {
        border-left-color: $color;
    }
}


// conversione unità px -> window width
@function toVw($pixel, $refer: 1920px) {
    @return (($pixel * 100) / $refer ) * 1vw;
}



// font 
// FONT-SIZE, LINE-HEIGHT, FONT-WEIGHT, COLOR, TEXT-TRANSFORM, LETTER-SPACING
@mixin font($fs, $lh: null, $fw: null, $color: null, $tt: null, $ls: null) {
	font-size: $fs;

    @if($lh) {
		line-height: toEm($lh, $fs);
	}

	@if($fw) {
		font-weight: $fw;
	}

	@if($color) {
		color: $color;
	}

	@if($tt) {
		text-transform: $tt;
	}

	@if($ls) {
		letter-spacing: toEm($ls, $fs);
	}
}

// conversione unità px -> em
@function toEm($px, $base: 16px, $mode: em) {

	@return ($px/$base) * 1#{$mode};
}

// conversione unità PSD -> em
@mixin letter-spacing($psdSize){
	letter-spacing: ($psdSize / 1000) *1em;
}