@font-face {
    font-family: 'ITC Kabel Std';
    src: url('../fonts/ITCKabelStd-Demi.eot');
    src: url('../fonts/ITCKabelStd-Demi.eot?#iefix') format('embedded-opentype'),
        url('../fonts/ITCKabelStd-Demi.woff2') format('woff2'),
        url('../fonts/ITCKabelStd-Demi.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'ITC Kabel Std';
    src: url('../fonts/ITCKabelStd-Bold.eot');
    src: url('../fonts/ITCKabelStd-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/ITCKabelStd-Bold.woff2') format('woff2'),
        url('../fonts/ITCKabelStd-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'ITC Kabel Std';
    src: url('../fonts/ITCKabelStd-Book.eot');
    src: url('../fonts/ITCKabelStd-Book.eot?#iefix') format('embedded-opentype'),
        url('../fonts/ITCKabelStd-Book.woff2') format('woff2'),
        url('../fonts/ITCKabelStd-Book.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'ITC Kabel Std';
    src: url('../fonts/ITCKabelStd-Ultra.eot');
    src: url('../fonts/ITCKabelStd-Ultra.eot?#iefix') format('embedded-opentype'),
        url('../fonts/ITCKabelStd-Ultra.woff2') format('woff2'),
        url('../fonts/ITCKabelStd-Ultra.woff') format('woff');
    font-weight: 800;
    font-style: normal;
}

